const setTarget = async (payload) => {
  try {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/api/yearTarget`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    });

    return res.json();
    // return await res.json();
  } catch (error) {
    console.log("error", error);
  }
};

const allTargets = async () => {
  let res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/viewAllYearsTarget`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );

  return res.json();
};
export default {
  setTarget,
  allTargets,
};
