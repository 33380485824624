import React, { useState, useEffect } from "react";
import englishToCyrillic from "util/englishToCyrillic";
import { useNavigate } from "react-router-dom";
// templates
import PrivateTemplate from "comp/templates/PrivateTemplate";
// services
import SalesDealsRest from "services/rest/sales-deals";
import ProductsRest from "services/rest/products";
// widgets
import Table from "comp/widgets/Table";
import Modal from "comp/widgets/Modal";
// ui comps
import Button from "comp/ui/Button";
import Input from "comp/ui/Input";
import Select from "comp/ui/Select";
// icons
import ICON_LIST from "assets/icons/ico-list.svg";
import ICON_DELETE from "assets/icons/delete.png";

import { toast } from 'react-toastify';


// styles
import "./style.css";

const SalesDeals = () => {
  const [salesDeals, setSalesDeals] = useState([]);
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRowModalOpen, setIsRowModalOpen] = useState(false);
  const [salesDeal, setSalesDeal] = useState({
    nameOfDeal: "",
    sumFrom: 0,
    sumTo: 0,
    percentage: 0,
    dateFrom: "",
    dateTo: "",
  });
  const navigate = useNavigate();
  const [filters, setFilters] = useState({ nameOfDeal: "" });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    (async () => {
      let d = await SalesDealsRest.getAll();
      let p = await ProductsRest.getAll();
      setSalesDeals(d);
      setProducts(p);
    })();
  }, []);

  const handleModalClose = () => setIsModalOpen(false);
  const handleRowModalClose = () => setIsRowModalOpen(false);

  const dealsColumns = [
    { name: "Name" },
    { name: "Sum from" },
    { name: "Sum to" },
    { name: "Percentage" },
    { name: "Date from" },
    { name: "Date to" },
    { name: "Edit" },
    { name: "Actions" },
  ];

  const onClickCreateDeal = async () => {
    try {
      let res = await SalesDealsRest.createDeal(salesDeal);
      setSalesDeals([...salesDeals, res.data]);
      setIsModalOpen(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const footerActions = [
    { label: "Cancel", action: handleModalClose, theme: "ghost" },
    { label: "Create", action: onClickCreateDeal },
  ];

  const handleOnClickAddProduct = (id) => {
    console.log("id", id);
    setIsRowModalOpen(true);
  };

  const rowFooterActions = [
    { label: "Cancel", action: handleRowModalClose, theme: "ghost" },
    { label: "Create", action: handleOnClickAddProduct },
  ];

  const handleOnChangeDeal = (e) => {
    setSalesDeal({
      ...salesDeal,
      [e.target.name]: e.target.value,
    });
  };

  const filteredSales = salesDeals.filter(
    (o) =>
      !filters.nameOfDeal ||
      o.nameOfDeal
        .trim()
        .toLowerCase()
        .includes(filters.nameOfDeal.toLowerCase()) ||
      o.nameOfDeal
        .trim()
        .toLowerCase()
        .includes(englishToCyrillic(filters.nameOfDeal))
  );

  const removeSalesDeal = async (id) => {
    const p = window.confirm("Are you sure you want to delete this sales deal?");
    if (!p) return;
    let res = await SalesDealsRest.remove(id);
    console.log('res', res)
    if (res?.status === 400) {
        toast.error(res?.message || 'Cannot be deleted!')
        return;
    } else if (res?.status === 200) {
        toast.success(res?.message || 'Successfully deleted!')
        setSalesDeal(salesDeals.filter(d => d.id !== id))
    } else {
        toast.error("Sales deal already removed!")
    }
  };

  return (
    <PrivateTemplate>
      <PrivateTemplate.AppSidebar>
        <div className="owners-filters">
          <Input
            label="Име"
            name="nameOfDeal"
            value={filters.nameOfDeal}
            onChange={handleOnChange}
          />
          {/* <Input label="Град"/> */}
        </div>
      </PrivateTemplate.AppSidebar>
      <div className="products-list">
        {/* <div className='deals-list__header'> */}
        <div className="products-list__header">
          <div className="products-list__header-left">
            <img src={ICON_LIST} />
            <span>Sales deals</span>
          </div>
          <Button
            type="warning"
            label="Create new deal"
            onClick={() => navigate("/sales-deals/add")}
          />
        </div>
        {/* </div>  */}
        <Table columns={dealsColumns}>
          {filteredSales?.map((d) => {
            return (
              <tr className="products-row" key={d.id}>
                <Table.Cell>{d.nameOfDeal}</Table.Cell>
                <Table.Cell>{d.sumFrom}</Table.Cell>
                <Table.Cell>{d.sumTo}</Table.Cell>
                <Table.Cell>{d.percentage}%</Table.Cell>
                <Table.Cell>{d.dateFrom}</Table.Cell>
                <Table.Cell>{d.dateTo}</Table.Cell>
                <Table.Cell>
                  <span
                    className="deals-row__add"
                    onClick={() => navigate(`view/${d.id}`)}
                  >
                    View Deal
                  </span>
                 
                  
                </Table.Cell>
                <Table.Cell>
                <img
                    src={ICON_DELETE}
                    className="products-row__edit"
                    onClick={() => removeSalesDeal(d.id)}
                  />
                </Table.Cell>
              </tr>
            );
          })}
        </Table>
        {isModalOpen && (
          <Modal title="Create new deal" footerActions={footerActions}>
            <div className="deals-modal">
              <Input
                name="nameOfDeal"
                value={salesDeal.nameOfDeal}
                onChange={handleOnChangeDeal}
                label="Deal name"
                customClassName="deals-custom-input"
              />
              <Input
                name="sumFrom"
                value={salesDeal.sumFrom}
                onChange={handleOnChangeDeal}
                label="Sum from"
                customClassName="deals-custom-input"
              />
              <Input
                name="sumTo"
                value={salesDeal.sumTo}
                onChange={handleOnChangeDeal}
                label="Sum to"
                customClassName="deals-custom-input"
              />
              <Input
                name="percentage"
                value={salesDeal.percentage}
                onChange={handleOnChangeDeal}
                label="Percentage"
                customClassName="deals-custom-input"
              />
              <Input
                type="month"
                name="dateFrom"
                value={salesDeal.dateFrom}
                onChange={handleOnChangeDeal}
                label="Date from"
                customClassName="deals-custom-input"
              />
              <Input
                type="month"
                name="dateTo"
                value={salesDeal.dateTo}
                onChange={handleOnChangeDeal}
                label="Date to"
                customClassName="deals-custom-input"
              />
            </div>
          </Modal>
        )}
        {isRowModalOpen && (
          <Modal title="Add new product" footerActions={rowFooterActions}>
            <Select options={products} />
          </Modal>
        )}
      </div>
    </PrivateTemplate>
  );
};

export default SalesDeals;
