import React, { useState } from 'react';
// styles
import './style.css';

const ProgressBar = (props) => {
    const [showPrice, setShowPrice] = useState(false);
    console.log('props', props)
    return (
        <div className='progress-bar__container' onMouseEnter={() => setShowPrice(true)} onMouseLeave={() => setShowPrice(false)}>  
            <div className={`progress-bar__header`}>
                <span>{props.label}</span>
                <span>{props.value}</span>
                {showPrice && props.sumEur &&
                <span className='progress-bar__hover'>
                    {props.sumEur.toLocaleString("mk-MK", {
                        style: "currency",
                        currency: "EUR",
                    })}
                </span>
                }
            </div>
            <div className={`progress-bar ${props.type}`}>
                <div className="progress-bar__value" style={{ width: props.value }}>

                </div>
            </div>
        </div>
    )
}

export default ProgressBar