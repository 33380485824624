import React, { useState } from 'react';
import { BarChart, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart } from 'recharts';
// styles
import './style.css';

const DashboardCharts = (props) => {

    // const [data, setData] = useState(props.data);
    const data = [
        // { name: 'Eureka', earning: 0 },
        // { name: 'Phoenix', earning: 0 },
        // { name: 'Europharm', earning: 0 },
        // { name: 'Zegin', earning: 0 },
        ...props.data, 
    ];

    // const data = [
    //     {
    //       name: 'Page A',
    //       uv: 5000,
    //       pv: 1400,
    //       amt: 2400,
    //     },
    //     {
    //       name: 'Page B',
    //       uv: 400,
    //       pv: 1398,
    //       amt: 2210,
    //     },
    //     {
    //       name: 'Page C',
    //       uv: 8000,
    //       pv: 9800,
    //       amt: 2290,
    //     },
    //     {
    //       name: 'Page D',
    //       uv: 2780,
    //       pv: 3908,
    //       amt: 2000,
    //     },
    //     {
    //       name: 'Page E',
    //       uv: 1890,
    //       pv: 4800,
    //       amt: 2181,
    //     },
    //     {
    //       name: 'Page F',
    //       uv: 2390,
    //       pv: 3800,
    //       amt: 2500,
    //     },
    //     {
    //       name: 'Page G',
    //       uv: 3490,
    //       pv: 4300,
    //       amt: 2100,
    //     },
    //   ];

    const CustomBar = (props) => {
        return (
            <rect
                x={props.x}
                y={props.y}
                width={props.width}
                height={props.height}
                fill="#27bd1c"
                rx={8}
            />
        )
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <p className="label">{`${label}`}</p>
              <p className="earning">{`Earning: ${payload[0].value} EUR`}</p>
            </div>
          );
        }
        return null;
      };

    const tooltipFormatter = (value) => `MKD ${value}`;

    return (
        <div className='dashboard-charts'>
            <ResponsiveContainer width="99%" height={400} className="barchart-container">
                {/* <div>div</div> */}
                <BarChart data={data} margin={{left: 30}} layout='vertical'>
                <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[0, props.target]}/>
                    <YAxis dataKey="name" type="category"/>
                    <Tooltip labelFormatter={(label) => `${label}`} formatter={tooltipFormatter} />
                    {/* <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[0, 500]} height={20} />
                    <YAxis dataKey="name" type="category" />
                    <Tooltip /> */}
                    <Legend />
                    <Bar dataKey="earning" fill="#27bd1c" className='barchart-container__bar' shape={<CustomBar />} maxBarSize={50}/>
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

    {/* <BarChart
    width={600}
    height={400}
    data={data}
    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    layout="vertical"
    >
    < strokeDasharray="3 3" />
    <XAxis type="nuCartesianGridmber" />
    <YAxis dataKey="name" type="category" />
    <Tooltip />
    <Legend />
    <Bar dataKe */}

export default DashboardCharts;
