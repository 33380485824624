import React, { useState, useEffect } from "react";
import PrivateTemplate from "comp/templates/PrivateTemplate";
import useDebounce from "util/useDebounce";
// widgets
import DashboardCharts from "comp/widgets/DashboardCharts";
// ui comps
import Input from "comp/ui/Input";
import Select from "comp/ui/Select";
import Button from "comp/ui/Button";
import ProgressBar from "comp/ui/ProgressBar";
import MultiSelect from "comp/ui/MultiSelect";
// icons
import ICON_FUNNEL from "assets/icons/ico-funnel.svg";
// styles
import "./style.css";

const trafficSources = [
  {
    value: "rx",
    label: "RX",
  },
  {
    value: "otd",
    label: "OTD",
  },
  {
    value: "tender",
    label: "Tender",
  },
];

const Dashboard = () => {

  const typeOfProductFakeData =  [
    {
        "typeOfProduct": "RX",
        "TotalSumEur": 1120732.564516129,
        "TotalQuantity": 641997
    },
    {
        "typeOfProduct": "TENDER",
        "TotalSumEur": 1139345.2396774194,
        "TotalQuantity": 80969.26000000001
    },
    {
        "typeOfProduct": "OTC",
        "TotalSumEur": 1190859.7981567383,
        "TotalQuantity": 409793
    }
];

  const filtersInit = {
    traffic: "",
    month: "",
    dateFrom: "",
    dateTo: "",
  };

  const [filters, setFilters] = useState(filtersInit);
  const [target, setTarget] = useState(0);
  const [pharmacies, setPharmacies] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [productTypes, setProductTypes] = useState([]);
  const [totalSumProductTypes, setTotalSumProductTypes] = useState(0);

  const debouncedFilters = useDebounce(filters, 250);

  const getTarget = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/yearTarget`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );

      const data = await res.json();
      if (!data.data) return;
      setTarget(
        data.data.yearTargetOtc +
          data.data.yearTargetRx +
          data.data.yearTargetTended
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const getDashboardData = async () => {
    try {
      const params = new URLSearchParams(debouncedFilters);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/dashboard?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );

      const productTypes = await fetch(
        `${process.env.REACT_APP_API_URL}/api/groupByTypeOfProduct?${params}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
        }
      );

      const data = await res.json();


      const productTypesData = await productTypes.json();
      setProductTypes(productTypesData.results);
      setTotalSumProductTypes(productTypesData.results.reduce((acc, curr) => {
        if (curr.typeOfProduct === "OTC" || curr.typeOfProduct === "RX" || curr.typeOfProduct === "TENDER") {
            return acc + curr.TotalSumEur;
        }
        return acc;
      }, 0));

      setPharmacies(
        data.sumPharmacies.map((pharmacy) => {
          return {
            name: pharmacy.pharmacyName,
            earning: pharmacy.total_sum,
          };
        })
      );

      setTotalSum(
        data.sumPharmacies.reduce((acc, curr) => {
          return acc + curr.total_sum;
        }, 0)
      );

      // setPharmacies(data.sumPharmacies);
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log('totalSumProductTypes', totalSumProductTypes)

  const onFilterChange = async () => {
    let filterPayload = {};

    
  }

  const calculateProductTypePercentage = num => (num / totalSumProductTypes) * 100;

  useEffect(() => {
    getTarget();
  }, []);

  useEffect(() => {
    if (filters.dateFrom) {
        if (!filters.dateTo) return 
        // return 
        getDashboardData();
    } else {
        getDashboardData();
    }
  }, [debouncedFilters]);

  const handleOnChangeFilters = (e) => {
    switch (e.target.name) {
        case 'month':
            setFilters({
                ...filtersInit,
                [e.target.name]: e.target.value
            })
            break;
        case 'dateFrom':
        case 'dateTo': 
            setFilters({
                ...filters,
                month: '',
                [e.target.name]: e.target.value
            })
            break;
        default:
            break;
    }
  };

//   console.log('filters', filters)

  let sum = 0;
  return (
    <PrivateTemplate>
      <PrivateTemplate.AppSidebar label="Filters" icon={ICON_FUNNEL}>
        <div className="dashboard-filters">
          {/* <Select label="Traffic Sources" options={trafficSources} name="traffic" onChange={handleOnChangeFilters} value={filters.traffic}/> */}
          {/* <MultiSelect
            isMulti={false}
            label="Извор"
            options={trafficSources}
            name="traffic"
            value={filters.traffic}
            placeholder="Одбери извор"
          /> */}
          {/* <Input type="" label="Traffic Sources" name="traffic" onChange={handleOnChangeFilters} value={filters.traffic}/> */}
          <Input
            type="month"
            label="Пребарувај по месец"
            name="month"
            onChange={handleOnChangeFilters}
            value={filters.month}
            height="medium"
          />
          <div className="dashboard-filters__group">
            <span>Месец од - до:</span>
            <Input
              type="month"
              label="Од"
              name="dateFrom"
              onChange={handleOnChangeFilters}
              value={filters.dateFrom}
              height="medium"
            />
            <Input
              type="month"
              label="До"
              name="dateTo"
              onChange={handleOnChangeFilters}
              value={filters.dateTo}
              height="medium"
            />
          </div>
        </div>
      </PrivateTemplate.AppSidebar>
      <div className="dashboard">
        <div className="dashboard-top">
          <div className="dashboard-top__card">
            <div className="circle one"></div>
            <div className="circle two"></div>
            <div className="total-earning">
              {/* <span className="total-earning__value">
                {(totalSumProductTypes * 62).toLocaleString("mk-MK", {
                  style: "currency",
                  currency: "MKD",
                })}
              </span> */}
              <span className="total-earning__value eur">
                {totalSumProductTypes.toLocaleString("mk-MK", {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
              <span className="total-earning__label">Total earning</span>
            </div>
          </div>
          <div className="dashboard-top__card">
            <div className="dashboard-top__card-header">
              <h2>Profit Target</h2>
              {/* <select>
                                {trafficSources.map((ts, i) => {
                                    if (i !== 0) {
                                        return (
                                            <option key={i} value={ts.value}>{ts.label}</option>
                                        )
                                    }
                                })}
                            </select> */}
            </div>
            {/* <h2 className='dashboard-top__card-title'>Profit Target</h2> */}
            <div className="dashboard-top__card-main">
              <span>
                {target === 0
                  ? "N/A"
                  : ((totalSum / target) * 100).toLocaleString("mk-MK", {
                      style: "currency",
                      currency: "MKD",
                    })}
              </span>
              <span>
                {target === 0
                  ? "N/A"
                  : (((totalSum / target) * 100) / 62).toLocaleString("mk-MK", {
                      style: "currency",
                      currency: "EUR",
                    })}
              </span>
              <ProgressBar />
              <div className="dashboard-top__card-main__group">
                <div>
                  <span>Current</span>
                  <span>{totalSum.toFixed(2)} </span>
                </div>
                <div>
                  <span>Target</span>
                  <span>{target}</span>
                </div>
                <div>
                  <span>Remaining</span>
                  <span>
                    {target === 0 ? 0 : (target - totalSum).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-top__card">
            <h2 className="dashboard-top__card-title">Traffic Sources</h2>
            <div className="dashboard-top__card-main">
                {productTypes.map(pt => {
                    if (pt.typeOfProduct === 'OTC' || pt.typeOfProduct === "RX" || pt.typeOfProduct === "TENDER") {
                        // sum += pt.TotalSumEur
                        let percentage = calculateProductTypePercentage(pt.TotalSumEur);
                        return (
                            <ProgressBar sumEur={pt.TotalSumEur} label={pt.typeOfProduct} value={`${percentage.toFixed(2)}%`} type="blue" />       
                        )
                    }
                })}
              {/* <ProgressBar label="RX" value="50%" type="blue" />
              <ProgressBar label="OTC" value="20%" type="blue" />
              <ProgressBar label="Tender" value="80%" type="blue" /> */}
            </div>
          </div>
        </div>
        <div className="dashboard-chart">
          <h1 className="dashboart-chart__header">Wholesale earnings</h1>
          <DashboardCharts target={target} data={pharmacies} />
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Dashboard;
