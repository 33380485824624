export default function englishToCyrillic(text) {
  const mapping = {
    a: "а",
    b: "б",
    c: "ц",
    d: "д",
    e: "е",
    f: "ф",
    g: "г",
    h: "х",
    i: "и",
    j: "j",
    k: "к",
    l: "л",
    m: "м",
    n: "н",
    o: "о",
    p: "п",
    q: "к",
    r: "р",
    s: "с",
    t: "т",
    u: "у",
    v: "в",
    w: "в",
    x: "h",
    y: "ј",
    z: "з",
    ch: "ч",
    sh: "ш",
    zh: "ж",
    nj: "њ",
    lj: "љ",
    dj: "џ",
  };

  const transformedText = text.replace(
    /(sh|zh|ch|dj|lj|nj|kj|gj|[a-z])/gi,
    function (match) {
      const char = match.toLowerCase();
      return mapping[char] || char;
    }
  );

  return transformedText.toLowerCase();
}
