import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// settings
import Settings from '.';
// comps
import Button from 'comp/ui/Button';
import MultiSelect from 'comp/ui/MultiSelect';
// rest
import UpdateDrugstoresRest from 'services/rest/updateCodes';
import DrugstoresRest from 'services/rest/drugstores';
// import ProductsRest from 'services/rest/products';
// styles
import './style.css';


const DrugstoresUpdate = () => {
  const [productsForUpdate, setProductsForUpdate] = useState([]);
  const [drugstoresForUpdate, setDrugstoresForUpdate] = useState([]);
  const [products, setProducts] = useState([]);
  const [drugstores, setDrugstores] = useState([]);
  const [activeDrugstore, setActiveDrugstore] = useState('');
  const [payload, setPayload] = useState({ codeOfArticle: '', drugstore_id: '' });

  useEffect(() => {
    (async () => {
      const drugstores = await DrugstoresRest.getAll();
      setDrugstores(drugstores.map(d => {
        return {
          label: `${d.nameDrugstore} - ${d.city.cityName} - ${d.owner.id}`,
          value: d.id,
        }
      }));
      // await getProductsForUpdate();
      await getDrugstoresForUpdate();
    })();
  }, []);

  const getDrugstoresForUpdate = async () => {
    const p = await UpdateDrugstoresRest.getDrugstoresForUpdate();
    console.log('p', p)
    const unique = {};
    for (let prod of p) {
      if (!(prod.nameOfBuyer in unique)) {
        unique[prod.nameOfBuyer] = prod;
      }
    }


    setDrugstoresForUpdate(Object.values(unique));
  }

  const handleOnChangeProduct = e => {
    setActiveDrugstore(e.target.value);
  };

  const hanldeOnChangeMulti = (e, s) => {
    setPayload({ [s.name]: e.value });
  };

  // console.log('payload', payload)

  // console.log('drugstores', drugstores)

  const handleOnUpdate = async () => {
    console.log('{ ...payload, codeOfArticle: activeDrugstore}', { ...payload, codeOfBuyer: activeDrugstore })
      try {
        await UpdateDrugstoresRest.updateDrugstoreById({ ...payload, codeOfBuyer: activeDrugstore });
        toast.success("Successfully updated!");
        await getDrugstoresForUpdate();
      }
      catch (e) {
        toast.error("Error ");
      }

  }

  // console.log('HERE', drugstores.filter(d => d.nameDrugstore === "ИРИС"))
  // console.log('drugstoresForUpdate', drugstoresForUpdate);
  console.log('activeDrugstore', activeDrugstore)

  return (
    <Settings>
      <div className='update-code-template'>
        <div className='update-code-template__main'>
          <div className='update-code-template__left'>
            <div className='update-code-template__left-header'>
              Drugstores from import
            </div>
            <div className='update-code-template__left-products'>
              {drugstoresForUpdate.length && drugstoresForUpdate.map(p => {
                return (
                  <div key={p.id} className='update-code-template__left-products-product'>
                    <div>
                      <p className='name-of-article'>{p.nameOfBuyer}</p>
                      <span>{p?.drugstores?.city?.cityName}</span></div>
                    <span>{p.file.pharmacy.drugstoreName}</span>
                    <span>{p.file.month}</span>
                    <input type="checkbox" onChange={handleOnChangeProduct} value={p.codeOfBuyer} checked={p.codeOfBuyer === activeDrugstore} />
                  </div>
                )
              })}
            </div>
          </div>
          <div className='update-code-template__right'>
            <div className='update-code-template__right-header'>
              Name of the drugstore
            </div>
            <div className='update-code-template__right-main'>
              <MultiSelect options={drugstores} onChange={hanldeOnChangeMulti} name="drugstore_id" />
            </div>
          </div>
        </div>
        <div className='update-code-template-actions'>
          {/* <div className='update-code-template-actions-info'>You've updated the product code successfully!</div> */}
          <Button label="Update" type="warning" onClick={handleOnUpdate} />
        </div>
      </div>
    </Settings>
  )
};

export default DrugstoresUpdate;
