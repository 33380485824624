import React, { useState, useEffect } from "react";
import Settings from ".";
import "./style.css";
import TargetRest from "services/rest/target";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// {
// id
// yearTargetOtc,
// yearTargetRx,
// yearTargetTended,
// year
// }
export default function AllTargets() {
  const [targets, setTargets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let u = await TargetRest.allTargets();
      if (u.status === 200) {
        setTargets(u.data);
      } else {
        toast.error("Failed to load targets");
      }
    })();
  }, []);

  const onClickView = (id) => {
    console.log("view user", id);
    navigate(`/settings/users/${id}`);
  };

  return (
    <Settings>
      <div className="users">
        <div className="users-header">
          {/* <span></span> */}
          <span>Year</span>
          <span>RX</span>
          <span>OTC</span>
          <span>Tended</span>
        </div>
        <div className="users-list">
          {targets?.map((t) => {
            return (
              <div key={t.id}>
                <span>{t.year}</span>
                <span>{t.yearTargetRx}</span>
                <span>{t.yearTargetOtc}</span>
                <span>{t.yearTargetTended}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Settings>
  );
}
