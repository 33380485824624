import React, { useState, useEffect } from "react";
import Settings from ".";
import { useParams } from "react-router-dom";
import UsersRest from "services/rest/users";
import Input from "comp/ui/Input";
import "./style.css";
import Button from "comp/ui/Button";
import Modal from "comp/widgets/Modal";
import MultiSelect from "comp/ui/MultiSelect";
import CitiesRest from "services/rest/cities";

const UserView = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [cities, setCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [payload, setPayload] = useState({ city_id: "", user_id: id });

  useEffect(() => {
    (async () => {
      let u = await UsersRest.getById(id);
      let c = await CitiesRest.getAll();
      if (c) {
        setAllCities(c.map((c) => ({ label: c.cityName, value: c.id })));
      }
      setCities(u.city_for_user);
      setUser(u);
    })();
  }, []);

  const onClickRemoveCity = async (id) => {
    await UsersRest.deleteCityById(id);
    setCities(cities.filter((c) => c.id !== id));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddCities = async () => {
    console.log("first");
    let c = await UsersRest.addCity(payload);
    if (c) {
      setCities([...cities, c]);
      setIsModalOpen(false);
    }
  };

  const hanldeOnChangeMulti = (e, s) => {
    setPayload({
      ...payload,
      [s.name]: e.value,
    });
  };
  const footerActions = [
    { label: "Cancel", action: handleCloseModal, theme: "ghost" },
    { label: "Add citiy", action: handleAddCities },
  ];

  return (
    <Settings>
      <div className="user-view">
        <div className="user-view__info">
          <h1 className="product-edit__main-header">User info</h1>
          <div className="user-view__info-container">
            <div className="user-view__info-left">
              <img src="https://cdn-icons-png.flaticon.com/512/2055/2055051.png" />
            </div>
            <div className="user-view__info-right">
              <Input label="Name" name="name" value={user.name} disabled />
              <Input label="Email" name="email" value={user.email} disabled />
              <Input
                label="Password"
                name="password"
                type="password"
                disabled
              />
              <Input
                label="Role"
                name="role"
                value={user.role?.roleName}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="user-view__cities">
          <h1 className="product-edit__main-header">Cities</h1>
          <div className="user-view__cities-container">
            <ul>
              {cities &&
                cities.map((c, i) => {
                  return (
                    <li key={i}>
                      {
                        allCities.find((city) => city.value === c.city_id)
                          ?.label
                      }
                      <span onClick={() => onClickRemoveCity(c.id)}>
                        &times;
                      </span>
                    </li>
                  );
                })}
            </ul>
            <Button label="Add city" type="warning" onClick={handleOpenModal} />
          </div>
        </div>
        <div className="user-view__bonus"></div>
      </div>
      {isModalOpen && (
        <Modal footerActions={footerActions}>
          asdasd
          <div className="user-cities-modal">
            <MultiSelect
              options={allCities}
              onChange={hanldeOnChangeMulti}
              name="city_id"
            />
          </div>
        </Modal>
      )}
    </Settings>
  );
};

export default UserView;
