import React, { useState, useEffect } from 'react';
// settings
import Settings from '.';
// comps
import Button from 'comp/ui/Button';
import MultiSelect from 'comp/ui/MultiSelect';
// rest
import UpdateProductCodesRest from 'services/rest/updateCodes';
import ProductsRest from 'services/rest/products';
// styles
import './style.css';

const ProductsUpdate = () => {
    const [productsForUpdate, setProductsForUpdate] = useState([]);
    const [products, setProducts] = useState([]);
    const [activeProduct, setActiveProduct] = useState('');
    const [payload, setPayload] = useState({ codeOfArticle: '', product_id: '' });


    useEffect(() => {
        (async() => {
            const products = await ProductsRest.getAll();
            setProducts(products?.map(p => {
                return {
                    label: p.nameOfProduct,
                    value: p.id
                }
            }));
            await getProductsForUpdate();
        })();
    }, []);

    const getProductsForUpdate = async () => {
        const p = await UpdateProductCodesRest.getProductsForUpdate();
        const unique = {};
        for (let prod of p) {
            if (!(prod.nameOfArticle in unique)) {
                unique[prod.nameOfArticle] = prod;
            }
        }
        
        setProductsForUpdate(Object.values(unique));
    }

    const handleOnChangeProduct = e => {
        setActiveProduct(e.target.value);
    };

    const hanldeOnChangeMulti = (e, s) => {
        setPayload({[s.name]: e.value});
    };

    const handleOnUpdate = async () => {
        await UpdateProductCodesRest.updateProductById({ ...payload, codeOfArticle: activeProduct});
        await getProductsForUpdate();
    }

    return (
        <Settings>
            <div className='update-code-template'>
                <div className='update-code-template__main'>
                    <div className='update-code-template__left'>
                        <div className='update-code-template__left-header'>
                            Products from import
                        </div>
                        <div className='update-code-template__left-products'>
                            {productsForUpdate.length && productsForUpdate?.map(p => {
                                return (
                                    <div key={p.id} className='update-code-template__left-products-product'>
                                      <p className='name-of-article'>{p.nameOfArticle}</p>
                                    <span>{p.file.pharmacy.drugstoreName}</span>
                                    <span>{p.file.month}</span>
                                        <input type="checkbox" onChange={handleOnChangeProduct} value={p.codeOfArticle} checked={p.codeOfArticle === activeProduct}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='update-code-template__right'>
                        <div className='update-code-template__right-header'>
                            Name of the product
                        </div>
                        <div className='update-code-template__right-main'>
                            <MultiSelect options={products} onChange={hanldeOnChangeMulti} name="product_id"/>
                        </div>
                    </div>
                </div>
                <div className='update-code-template-actions'>
                    {/* <div className='update-code-template-actions-info'>You've updated the product code successfully!</div> */}
                    <Button label="Update" type="warning" onClick={handleOnUpdate}/>
                </div>
            </div>
        </Settings>
    )
};

export default ProductsUpdate;
