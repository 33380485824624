import React from 'react';
import Settings from '../Settings';

const SettingsPharmacies = () => {
  return (
    <Settings>
        <div>SettingsPharmacies</div>
    </Settings>
  )
}

export default SettingsPharmacies;