const getAll = async () => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/generalFilterData`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data;
  } catch (error) {
    console.log("error", error);
  }
};

const downloadReport = async (filters) => {
  try {
    // console.log('API', `${process.env.REACT_APP_API_URL}/api/financialExport?${params.toString()}`)
    console.debug("filters", JSON.stringify(filters));
    const d = await fetch(
      `${process.env.REACT_APP_API_URL}/api/financialExport`,
      {
        method: "POST",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(filters),
      }
    ).catch((e) => {
      console.log("e", e);
    });

    let b = await d.blob();
    const url = window.URL.createObjectURL(b);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'report.xlsx';
    a.style.display = 'none';
    document.body.appendChild(a);

    a.click();

    // // Clean up after download
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    // console.log(' d',  b)
  } catch (error) {
    console.log("error", error);
  }
};

export default {
  getAll,
  downloadReport,
};
