import React, { useState, useEffect } from 'react';
import Settings from '.';
import Input from 'comp/ui/Input';
import Button from 'comp/ui/Button';
import target from 'services/rest/target';
import { toast } from 'react-toastify';

const Target = () => {
  const [fields, setFields] = useState({ yearTargetRx: null, yearTargetOtc: null, yearTargetTended: null, year: null });
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: Number(e.target.value)
    })
  };

  const onSave = async () => {
    setIsLoading(true);
    const res = await target.setTarget(fields);
    if (res.status === 200) {
      toast.success('Target saved successfully');
    } else {
      toast.error('Failed to save target')
    }

    setIsLoading(false);
  };

  return (
    <Settings>
      <div className='target'>
        <h1 className='settings-header-label'>Target</h1>
        <div className='target-fields__group'>
          <Input label="Rx" value={fields.rx} name="yearTargetRx" onChange={onChange} />
          <Input label="Otc" value={fields.otc} name="yearTargetOtc" onChange={onChange} />
          <Input label="Tender" value={fields.tender} name="yearTargetTended" onChange={onChange} />
        </div>
        <div className='target-fields__group'>
          <Input label="Year" customClassName="target-year" name="year" onChange={onChange} />
          <Button label="Save" onClick={onSave} isLoading={isLoading} />
        </div>
      </div>
    </Settings>
  )
}

export default Target
