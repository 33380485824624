const updateProducts = async () => {
    try {
        
    } catch (error) {
        console.log('error', error)
    }
};

const getProductsForUpdate = async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/editImportProductsIds`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        const data = await res.json();
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const updateProductById = async (data) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/updateImportProductsIds`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        });   
    } catch (error) {
        console.log('error', error);
    }
}; 

const getDrugstoresForUpdate = async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/editImportDrugstoreIds`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        const data = await res.json();
        // console.log('data', data);
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const updateDrugstoreById = async (data) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/updateImportDrugstoreIds`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        });   
    } catch (error) {
        console.log('error', error);
    }
};

export default {
    updateProducts,
    getProductsForUpdate,
    updateProductById,
    getDrugstoresForUpdate,
    updateDrugstoreById
};