import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useDebounce from "util/useDebounce";
import englishToCyrillic from "util/englishToCyrillic";
// templates
import PrivateTemplate from "comp/templates/PrivateTemplate";
// widgets
import Table from "comp/widgets/Table";
// services
import DrugstoresRest from "services/rest/drugstores";
// ui comps
import Input from "comp/ui/Input";
import Button from "comp/ui/Button";
import Switch from "comp/ui/Switch";
// icons
import ICON_LIST from "assets/icons/ico-list.svg";
import ICON_FUNNEL from "assets/icons/ico-funnel.svg";
import ICON_EDIT from "assets/icons/ico-edit.svg";
import ICON_DELETE from "assets/icons/delete.png";
// animations
import ANIMATION_LOADER from "assets/animations/loader.gif";
// styles
import "./style.css";
import { toast } from 'react-toastify';

const Drugstores = () => {
  const navigate = useNavigate();
  const filtersInit = {
    name: "",
    city: "",
    owner: "",
  };

  const [drugstores, setDrugstores] = useState([]);
  const [filters, setFilters] = useState(filtersInit);
  const [loading, setLoading] = useState(false);
  const debouncedFilters = useDebounce(filters, 150);

  const fetchDrugstores = async () => {
    setLoading(true);
    let d = await DrugstoresRest.getAll();
    setDrugstores(d);
    setLoading(false);
  };

  useEffect(() => {
    fetchDrugstores();
  }, []);

  const drugstoreColumns = [
    { name: "Name" },
    { name: "City" },
    { name: "Address" },
    { name: "Owner" },
    { name: "Actions", className: "left-auto__header" },
  ];

  const handleOnChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const filteredDrugstores = drugstores.filter((d) => {
    return (
      (!debouncedFilters.name ||
        d.nameDrugstore
          .trim()
          .toLowerCase()
          .includes(debouncedFilters.name.toLowerCase()) ||
        d.nameDrugstore
          .toLowerCase()
          .includes(englishToCyrillic(debouncedFilters.name))) &&
      (!debouncedFilters.city ||
        d.city.cityName
          ?.toLowerCase()
          .includes(debouncedFilters.city.toLowerCase()) ||
        d.city.cityName
          ?.toLowerCase()
          .includes(englishToCyrillic(debouncedFilters.city))) &&
      (!debouncedFilters.owner ||
        d.owner.ownerName
          ?.toLowerCase()
          .includes(debouncedFilters.owner.toLowerCase()) ||
        d.owner.ownerName
          .toLowerCase()
          .includes(englishToCyrillic(debouncedFilters.owner)))
    );
  });

  const handleOnClickEdit = (id) => {
    navigate(`/drugstores/${id}`);
  };


// TODO
  const removeDrugstore = async (id) => {
    const p = window.confirm("Are you sure you want to delete this drugstore?");
    if (!p) return;
    let res = await DrugstoresRest.removeDrugstore(id);
    if (res?.status === 400) {
        toast.error(res?.message || 'Cannot be deleted!')
        return;
    } else if (res?.status === 200) {
        toast.success(res?.message || 'Successfully deleted!')
        setDrugstores(drugstores.filter(d => d.id !== id))
    } else {
        toast.error("Drugstore already removed!")
    }
  };

  return (
    <PrivateTemplate>
      <PrivateTemplate.AppSidebar label="Filters" icon={ICON_FUNNEL}>
        <div className="products-filters">
          <Input
            type="text"
            label="Drugstore name"
            height="medium"
            name="name"
            value={filters.name}
            onChange={handleOnChange}
          />
          <Input
            type="text"
            label="Drugstore city"
            height="medium"
            name="city"
            value={filters.city}
            onChange={handleOnChange}
          />
          <Input
            type="text"
            label="Drugstore owner"
            height="medium"
            name="owner"
            value={filters.owner}
            onChange={handleOnChange}
          />
        </div>
      </PrivateTemplate.AppSidebar>
      <div className="products-list">
        <div className="products-list__header">
          <div className="products-list__header-left">
            <img src={ICON_LIST} />
            <span>Drugstores</span>
          </div>
          <Button
            type="warning"
            label="Add Drugstore"
            onClick={() => navigate("/drugstores/add")}
          />
        </div>
        <Table columns={drugstoreColumns}>
          {filteredDrugstores?.map((d, i) => (
            <tr className="products-row" key={i}>
              <Table.Cell>{d.nameDrugstore}</Table.Cell>
              <Table.Cell>{d.city.cityName}</Table.Cell>
              <Table.Cell>{d.address}</Table.Cell>
              <Table.Cell>{d.owner.ownerName}</Table.Cell>
              <Table.Cell className="left-auto">
                <img
                  src={ICON_EDIT}
                  className="products-row__edit"
                  onClick={() => handleOnClickEdit(d.id)}
                />
                <img
                    src={ICON_DELETE}
                    className="products-row__edit"
                    onClick={() => removeDrugstore(d.id)}
                  />
              </Table.Cell>
            </tr>
          ))}
        </Table>
      </div>
    </PrivateTemplate>
  );
};

export default Drugstores;
