import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PrivateTemplate from 'comp/templates/PrivateTemplate';
import { Menu, MenuItem } from 'react-pro-sidebar';
import SettingsReports from '../SettingsReports';
import UpdateProductCodesRest from 'services/rest/updateCodes';
import UpdateDrugstoresRest from 'services/rest/updateCodes';
import './style.css';

const Settings = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    // console.log('location', location)
    const [selectedItem, setSelectedItem] = useState(null);
    const [productsForUpdate, setProductsForUpdate] = useState(false);
    const [drugstoresForUpdate, setDrugstoresForUpdate] = useState(false);

    const getData = async () => {
        let promises = [UpdateProductCodesRest.getProductsForUpdate(), UpdateDrugstoresRest.getDrugstoresForUpdate()];
        let [p, d] = await Promise.all(promises);
        if (p?.length) {
            setProductsForUpdate(true);
        }
        if (d?.length) {
            setDrugstoresForUpdate(true);
        }
    };

    // const getProductsForUpdate = async () => {
    //     const p = await UpdateProductCodesRest.getProductsForUpdate();
    //     if (p?.length) {
    //         setProductsForUpdate(true);
    //     }
    // };

    // const getDrugstoresForUpdate = async () => {
    //     const d = await UpdateDrugstoresRest.getDrugstoresForUpdate();
    //     if (d?.length) {
    //         setDrugstoresForUpdate(true);
    //     }
    // };

    const handleOnClickItem = (item) => {
        setSelectedItem(item);
        navigate(`/settings/${item}`);
    };

    useEffect(() => {
        getData();
    }, [])

    return (
        <PrivateTemplate>
            {/* <PrivateTemplate.AppSidebar>
                <Menu
                    menuItemStyles={{
                        button: {
                        // the active class will be added automatically by react router
                        // so we can use it to style the active menu item
                        [`&.ps-active`]: {
                            backgroundColor: '#498a49',
                            color: '#fff',
                        },
                        },
                    }}
                >
                    <MenuItem onClick={() => handleOnClickItem('add-report')} active={location.pathname.includes('add-report')}>Add Report</MenuItem>
                    <MenuItem onClick={() => handleOnClickItem('add-pharmacy')} active={location.pathname.includes('add-pharmacy')}>Add Pharmacy</MenuItem>
                    <MenuItem onClick={() => handleOnClickItem('add-wholesaler')} active={location.pathname.includes('add-wholesaler')}>Add Wholesaler</MenuItem>
                    <MenuItem onClick={() => handleOnClickItem('add-owner')} active={location.pathname.includes('add-owner')}>Add Owner</MenuItem>
                    <MenuItem onClick={() => handleOnClickItem('add-employee')} active={location.pathname.includes('add-employee')}>Add Employee</MenuItem>
                </Menu>
            </PrivateTemplate.AppSidebar>
            {props.children} */}
            {/* {handleSelectedItem()} */}
            <div className='width-container'>
                <div className='settings-v2'>
                    <div className='settings-v2__header'>
                        <span>Settings</span>
                    </div>
                    <div className='settings-v2__main'>
                        <SettingsSidebar productsForUpdate={productsForUpdate} drugstoresForUpdate={drugstoresForUpdate}/>
                        {props.children}
                    </div>
                </div>
            </div>
        </PrivateTemplate>
    )
}

const SettingsSidebar = (props) => {
    let navigate = useNavigate();


    return (
        <div className='settings-sidebar'>
            <ul>
                <li onClick={() => navigate('/settings/add-user')}>Add user</li>
                <li onClick={() => navigate('/settings/add-report')}>Add report</li>
                <li onClick={() => navigate('/settings/users')}>All users</li>
                <li onClick={() => navigate('/settings/all-targets')}>All targets</li>
                <li title={props.productsForUpdate && 'Products for update'} className={props.productsForUpdate ? 'settings-sidebar__info' : ''} onClick={() => navigate('/settings/products-update')}>Product codes for update</li>
                <li title={props.drugstoresForUpdate && 'Drugstores for update'} className={props.drugstoresForUpdate ? 'settings-sidebar__info' : ''} onClick={() => navigate('/settings/drugstores-update')}>Drugstore codes for update</li>
                <li onClick={() => navigate('/settings/set-target')}>Set target</li>
            </ul>
        </div>
    )
}

export default Settings
