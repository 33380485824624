import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PrivateTemplate from 'comp/templates/PrivateTemplate';
import Button from 'comp/ui/Button';
import './style.css';
import { OwnerDetails } from '../OwnerDetails';
import Input from 'comp/ui/Input';
import ProductsRest from 'services/rest/products';
import SalesDealsRest from 'services/rest/sales-deals';
import Table from 'comp/widgets/Table';
import ICON_REMOVE from 'assets/icons/ico-remove.svg';
import Modal from 'comp/widgets/Modal';
import Select from 'react-select';
import { toast } from 'react-toastify';

const SalesDealView = () => {

    const [activeTab, setActiveTab] = useState(1);
    const [deal, setDeal] = useState({});
    const [products, setProducts] = useState([]);
    const [dealProducts, setDealProducts] = useState([]);
    const params = useParams();
    const [isDealDone, setIsDealDone] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [ownerDeals, setOwnerDeals] = useState([]);
    const [activeRow, setActiveRow] = useState(0);
    const id = params.id;
    const qdeal = 'quarterly-deals';
    const navigate = useNavigate();
    console.log('params', params)

    const handleModalOpen = () =>  setIsModalOpen(true);
    const handleModalCancel = () =>  setIsModalOpen(false);

    const handleDeleteProduct = async (id) => {
        let res = await SalesDealsRest.deleteProductById(id);
        if (res.status === 200) {
            setDealProducts(dealProducts.filter(dp => dp.id !== id));
        }
    };
    console.log('deal', deal)
    
    useEffect(() => {
        (async() => {
            if (params.deal === qdeal) {
                let res = await SalesDealsRest.getDealQ(id);
                console.log('res', res)
                setDeal(res);
                console.log('id', id)
            } else {            
                let promises = [
                    ProductsRest.getAll(),
                    SalesDealsRest.getOneById(id),
                    SalesDealsRest.getProductsByDealId(id)
                ];
                
                let [products, deal, dealProducts] = await Promise.all(promises);
                let fp = products.filter(p => {
                    let exist = dealProducts.length > 0 && dealProducts.find(dp => dp.product_id === p.id);
                    if (!exist) {
                        return p;
                    }
                });
                setDeal(deal);
                setProducts(fp.map(p => {
                    return {
                        value: p.id,
                        label: p.nameOfProduct
                    }
                }));
                setDealProducts(dealProducts);
            }
        })()
    }, []);

    // useEffect(() => {
    //     (async() => {

    //     })();
    // }, [id])

    useEffect(() => {
        (async() => {
            let now = new Date();
            let givenDate = new Date(deal.dateTo);
            if (givenDate < now) {
                // let res = await SalesDealsRest.getOwnerDeals(id);
                // if (qdeal !== params.deal){
                //     await SalesDealsRest.ownerDealStore({deal_id: id});
                // } else if (qdeal === params.deal) {
                //     await SalesDealsRest.ownerQuarterlyStore({quarterly_id: id});
                // }
                setIsDealDone(true);
                console.log('deal.dateTo', deal.dateTo)
            }
        })();
    }, [deal.dateTo])

    const onClickSaveOwners = async () => {
        if (isDealDone) {
            let res = await SalesDealsRest.ownerDealStore({ deal_id: id });
            console.log('res', res)
            if (res.status === 500) {
                toast.info("Owners have already been stored");
            } else {
                toast.success("Owners are saved successfully");
            }
            // await getOwnerDeals(id);
            return;
        }
        toast.info("Deal not yet done");
    };

    const onClickSaveQOwners = async () => {
        // if (isDealDone) {}
        if (isDealDone) {
            let res = await SalesDealsRest.ownerQuarterlyStore({ quarterly_id: id })
            console.log('res', res);
            if (res.status === 500) {
                toast.info('Owners have already been stored');
            } else {
                toast.success('Owners are saved successfully');
            }
            return;
        }
        toast.info("Deal not yet done");
    }


    const handleOnChangeDeal = (e) => {
        setDeal({
            ...deal, 
            [e.target.name]: e.target.value
        });
    };
    const handleTabChange = async (e) => {
        if (!isDealDone) return;
        console.log('e.target.dataset.index', e.target.dataset.index)
        if (e.target.dataset.index == '2' && qdeal !== params.deal) {
            console.log('THIS IS HAPPENING');
            await getOwnerDeals(id); 
        } else if (e.target.dataset.index == '2' && qdeal === params.deal) {
            await getOwnerQDeals(id);
        }
        setActiveTab(parseInt(e.target.dataset.index));
    };

    const getOwnerQDeals = async (id) => {
        let res = await SalesDealsRest.getOwnerQDeals(id);
        // console.log('res', res)
        setOwnerDeals(Object.values(res));
    };

    const getOwnerDeals = async (id) => {
        let res = await SalesDealsRest.getOwnerDeals(id);
        console.log('res', res)
        setOwnerDeals(Object.values(res));
    };

    console.log('ownerDeals', ownerDeals)

    const columns = [
        {
            name: 'Product name'
        },
        {
            name: 'Action',
            className: 'left-auto__header'
        }
    ];

    const handleSelectedOptions = (payload) => {
        setSelectedOptions(payload)
    };

    const handleAddProduct = async () => {
        console.log('')
        const ids = selectedOptions.map(option => option.value)
        const payload = {
            deal_id: id,
            products: ids
        }

        console.log('payload', payload)
        await SalesDealsRest.addProducts(payload);
        let res = await SalesDealsRest.getProductsByDealId(id);
        setDealProducts(res);
        setIsModalOpen(false);
        // console.log('res', res)
    }

    const footerActions = [
        { label: 'Cancel', action: handleModalCancel, theme: 'ghost' },
        { label: 'Add products', action: handleAddProduct},
    ];

    const toggleRow = (e, id) => {
        // console.log('id', id)
        // e.stopPropagation();
        if (e.target.classList.contains('deals__table-header')) {
            setActiveRow(id === activeRow ? 0 : id);
        }
        // setActiveTab(id === activeTab ? 0 : id);
    };

    const handleOnClickBackBtn = () => {
        qdeal !== params.deal ?
        navigate('/sales-deals') : navigate('/quarterly-deals');
    };

    const downloadReport = async () => {
        if (qdeal !== params.deal) {
            await SalesDealsRest.downloadReport(id);
        } else { 
            await SalesDealsRest.downloadReportQ(id);
        }
    };

   

    return (
        <PrivateTemplate>
            <div className='width-container'>
                <div className='sales-deal-view'>
                    <div className='sales-deal-view__header'>
                        <div className='sales-deal-view__header__bck-btn' onClick={handleOnClickBackBtn}>
                            <span>{qdeal !== params.deal ? 
                            "Back to sales deals view"
                            : "Back to quarterly deals view"}</span>
                            <img />
                        </div>  
                        <span className='sales-deal-view__header-dname'>
                            {qdeal !== params.deal ? deal.nameOfDeal : deal.quarterlyName}
                        </span>
                        <div className='deal-header__actions'>
                        {qdeal !== params.deal &&
                        <Button type="warning" label="Add Products" onClick={handleModalOpen}/>
                        }
                        <Button label="Export" onClick={downloadReport}/>
                        </div>
                    </div>
                    <div className='sales-deal-view__main'>
                        <ul className='sales-deal-view__main-tabs'>
                            <li className={`${activeTab === 1 && 'active'}`} data-index={1} onClick={handleTabChange}>Deal info</li>
                            <li title={!isDealDone ? 'deal not done yet' : ''} className={`${!isDealDone && 'disabled' } ${activeTab === 2 && 'active'}`} data-index={2} onClick={handleTabChange}>Owners</li>
                            <Button label="Save Owners" onClick={qdeal !== params.deal ? onClickSaveOwners : onClickSaveQOwners}/>
                        </ul>
                        {activeTab === 1
                        ?
                        <>
                        <div className='sales-deal-view__main-fields'>
                            {/* <h1 className='product-edit__main-header'>Deal info</h1> */}
                            {/* <div className='product-edit__main-inputs'> */}
                                <Input label="Action name" customClassName="product-input" name="nameOfDeal" onChange={handleOnChangeDeal} value={params.deal === qdeal ? deal.quarterlyName : deal.nameOfDeal} disabled/>
                                <Input label="Percentage" type="number" customClassName="product-input" name="percentage" onChange={handleOnChangeDeal} value={deal.percentage} disabled/>
                                <Input label="Sum from" type="number" customClassName="product-input" name="sumFrom" onChange={handleOnChangeDeal} value={deal.sumFrom} disabled/>
                                <Input label="Sum to" type="number" customClassName="product-input" name="sumTo" onChange={handleOnChangeDeal} value={deal.sumTo} disabled/>
                                <Input label="Date from" type="month" customClassName="product-input" name="dateFrom" onChange={handleOnChangeDeal} value={deal.dateFrom} disabled/>
                                <Input label="Date to" type="month" customClassName="product-input" name="dateTo" onChange={handleOnChangeDeal} value={deal.dateTo} disabled/>
                            {/* </div> */}
                        </div>
                        {qdeal !== params.deal && 
                        <div className='sales-deal-view__main-products'>
                            <h1 className='product-edit__main-header sales-deal-view__main-products'>Deal Products</h1>
                            <Table columns={columns} customClassName="dark border-radius">
                                {dealProducts.map((dp, i) => (
                                    <tr className='owner-pharmacies-row' key={i}>
                                        <Table.Cell>{dp.products.nameOfProduct}</Table.Cell>
                                        <Table.Cell className="left-auto">
                                            <img src={ICON_REMOVE} onClick={() => handleDeleteProduct(dp.id)} />
                                        </Table.Cell>
                                    </tr>
                                ))}
                            </Table>
                        </div>
                        }
                        {isModalOpen && qdeal !== params.deal &&
                        <Modal title="Add product" footerActions={footerActions}>
                            <div className='add-product-modal'>
                                <span>Search for products</span>
                                <Select options={products} isMulti={true} onChange={handleSelectedOptions} className='tag-select'/>
                            </div>
                        </Modal>
                    }
                        </>
                        : 
                        <div>
                            <div className='deals__table'>
                                {ownerDeals.length && ownerDeals.map((od, i) => {
                                    let totalSum = od.reduce((sum, product) => sum + product.total, 0)
                                    return (
                                        <div className='deals__table-row' key={i} onClick={(e) => toggleRow(e, od[0].owner_id)}>
                                            <div className='deals__table-header'>
                                                <span>{od[0].ownerName}</span>
                                                <span>Total: {totalSum}</span>
                                            </div>
                                            {activeRow === od[0].owner_id &&
                                                <div className='deals__table-content'>
                                                    <div className='deals__table-content__header'>
                                                        <span className='deals__table-content__header-product'>Product name</span>
                                                        <span className='deals__table-content__header-price'>Price</span>
                                                        <span className='deals__table-content__header-qty'>Quantity</span>
                                                        <span className='deals__table-content__header-total'>Total</span>
                                                    </div>
                                                    {od.map((p, i) => {
                                                        return (
                                                            <div className='deals__table-content__row' key={i}>
                                                                <span className='deals__table-content__row-product'>{p.nameOfProduct}</span>
                                                                <span className='deals__table-content__row-price'>{p.priceOfProduct}</span>
                                                                <span className='deals__table-content__row-qty'>{p.quantity}</span>
                                                                <span className='deals__table-content__row-total'>{p.total}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </PrivateTemplate>
    )
};

export default SalesDealView;
