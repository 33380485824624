const getAll = async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/deals`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        const data = await res.json();
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const createDeal = async (payload) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/dealStore`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            body: JSON.stringify(payload)
        }); 
        
        return await res.json();
    } catch (error) {
        console.log('error', error);
    }
};

const addProducts = async (payload) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/dealProductStore`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            body: JSON.stringify(payload)
        });

        return await res.json();
    } catch (error) {
        console.log('error', error);
    }
};

const getOneById = async (id) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/deals/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
        });

        // console.log('res', await res.json());
        let data = await res.json();  
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const getProductsByDealId = async (id) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/dealProduct/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        let data = await res.json();
        // console.log('data', data);
        // console.log('res', res);
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const deleteProductById = async(id) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/dealProductDelete/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        let data = await res.json();
        console.log('data', data)
        return data;
    } catch (error) {
        console.log('error', error);
    }
};

const getAllQ = async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/quarterlyDeal`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        const data = await res.json();
        // console.log('data', data)
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
}

const createDealQ = async (payload) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/quarterlyStore`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            body: JSON.stringify(payload)
        }); 
        
        return await res.json();
    } catch (error) {
        console.log('error', error);
    }
};

const getDealQ = async (id) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/quarterlyEdit/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
        });

        // console.log('res', await res.json());
        let data = await res.json();  
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const getOwnerDeals = async (id) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/dealsOwner/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        let data = await res.json();
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const getOwnerQDeals = async (id) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/quarterlyDeal/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        let data = await res.json();
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const ownerDealStore = async (payload) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/ownerDealStore`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            method: 'POST',
            body: JSON.stringify(payload)
        });

        let data = await res.json();
        return data;
    } catch (error) {
        console.log('error', error);
    }
}

const ownerQuarterlyStore = async (payload) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/ownerQuarterlyStore`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            method: 'POST',
            body: JSON.stringify(payload)
        });

        let data = await res.json();
        return data;
    } catch (error) {
        console.log('error', error);
    }
};

const downloadReport = async (id) => {
    try {
        const d = await fetch(`${process.env.REACT_APP_API_URL}/api/salesDealExport/${id}`, {
            // method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
            
            // data: JSON.stringify({deal_id: id})

        });

        let b = await d.blob()
        const url = window.URL.createObjectURL(b);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.xlsx';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        console.log(' d',  b)
    } catch (error) {
        console.log('error', error);
    }
}

const downloadReportQ = async (id) => {
    try {
        const d = await fetch(`${process.env.REACT_APP_API_URL}/api/quarterlyDealExport/${id}`, {
            // method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            // data: JSON.stringify({deal_id: id})

        });

        let b = await d.blob()
        const url = window.URL.createObjectURL(b);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.xlsx';
        a.style.display = 'none';
        document.body.appendChild(a);

        a.click();

        // Clean up after download
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        console.log(' d',  b)
    } catch (error) {
        console.log('error', error);
    }
}

const removeSalesDeal = async (id) => {
    // try {
    //     const res = await fetch(`${process.env.REACT_APP_API_URL}/api/deleteProduct/${id}`, {method: 'DELETE', headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    //       }});
    //     return await res.json();
    // } catch (error) {
    //     console.log('error', error);
    // }
}

const remove = async (id) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/dealDelete/${id}`, {method: 'DELETE', headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          }});
        let data = await res.json();
        return data;
    } catch (error) {
        console.log('error', error);
    }
}

const removeQ = async (id) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/quarterlyDelete/${id}`, {method: 'DELETE', headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          }});
        let data = await res.json();
        console.log('data', data)
        return data;
    } catch (error) {
        console.log('error', error);
    }
}

export default {
    getAll,
    createDeal,
    addProducts,
    getOneById,
    getProductsByDealId,
    deleteProductById,
    getAllQ,
    createDealQ,
    getOwnerDeals,
    ownerDealStore,
    getDealQ,
    ownerQuarterlyStore,
    getOwnerQDeals,
    downloadReport,
    downloadReportQ,
    removeSalesDeal,
    remove,
    removeQ
};