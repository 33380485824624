const create = async (data) => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/createUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        }); 
        
        return await res.json();
    } catch (error) {
        console.log('error', error);
    }
};

const getAll = async () => {
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });
        
        const data = await res.json();
        console.log('data', data)
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const getById = async (id) => { 
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            }
        });
        
        const data = await res.json();
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};

const deleteCityById = async (id) => { 
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/deleteCityForUser/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
            },
            method: 'DELETE'
        });
        
        const data = await res.json();
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};


const addCity = async (payload) => { 
    try {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/api/citiesForUser`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
            },
            method: 'POST',
            body: JSON.stringify(payload)
        });
        
        const data = await res.json();
        console.log('data', data)
        return data.data;
    } catch (error) {
        console.log('error', error);
    }
};




export default {
    create,
    getAll,
    getById,
    deleteCityById,
    addCity
};