import React from 'react';
import Settings from '../Settings';

const SettingsWholesalers = () => {
    return (
        <Settings>
            <div>SettingsWholesalers</div>
        </Settings>
    )
};

export default SettingsWholesalers;