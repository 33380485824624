import React from 'react';
import Settings from '../Settings';

const SettingsEmployees = () => {
    return (
        <Settings>
            <div>SettingsEmployees</div>
        </Settings>
    )
};

export default SettingsEmployees;