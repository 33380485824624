import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// templates
import PrivateTemplate from "comp/templates/PrivateTemplate";
// widgets
import Table from "comp/widgets/Table";
// services
import ProductsRest from "services/rest/products";
// ui comps
import Input from "comp/ui/Input";
import Button from "comp/ui/Button";
import Switch from "comp/ui/Switch";
// icons
import ICON_LIST from "assets/icons/ico-list.svg";
import ICON_FUNNEL from "assets/icons/ico-funnel.svg";
import ICON_EDIT from "assets/icons/ico-edit.svg";
import ICON_DELETE from "assets/icons/delete.png";
// animations
import ANIMATION_LOADER from "assets/animations/loader.gif";
// styles
import "./style.css";
// fake data
import MultiSelect from "comp/ui/MultiSelect";
import Modal from "comp/widgets/Modal";
import { toast } from 'react-toastify';

const productTypes = [
  {
    value: "RX",
    label: "RX",
  },
  {
    value: "OTC",
    label: "OTC",
  },
  {
    value: "TENDER",
    label: "TENDER",
  },
];

const productsColumns = [
  {
    name: "Name",
  },
  {
    name: "Type of product",
  },
  {
    name: "List of product",
  },
  {
    name: "Price",
    className: "price__header",
  },
  {
    name: "Actions",
    className: "left-auto__header",
  },
];

const Products = () => {
  const navigate = useNavigate();

  const filtersInit = {
    name: "",
    typeOfProduct: "",
    list_one: false,
    list_two: false,
    list_three: false,
  };

  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState(filtersInit);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const onClickModalOpen = () => setIsModalOpen(true);
  const onClickModalClose = () => setIsModalOpen(false);

  const fetchProducts = async () => {
    setLoading(true);
    let p = await ProductsRest.getAll();
    setProducts(p);
    setLoading(false);
  };

  const handleOnChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnChangeType = (e, s) => {
    setFilters({
      ...filters,
      [s.name]: e?.value || "",
    });
  };

  const handleOnChangeSwitch = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.checked,
    });
  };

  let filteredProducts = products.filter((p) => {
    return (
      (!filters.name ||
        p.nameOfProduct
          .trim()
          .toLowerCase()
          .includes(filters.name.toLowerCase())) &&
      (!filters.typeOfProduct || p.typeOfProduct === filters.typeOfProduct) &&
      (!filters.list_one || p.listOfProduct === "1") &&
      (!filters.list_two || p.listOfProduct === "2") &&
      (!filters.list_three || p.listOfProduct === "3")
    );
  });

  const handleOnClickEdit = (id) => {
    navigate(`/products/${id}`);
  };

  const onClickAddProduct = () => {
    navigate(`/products/add`);
  };

  console.log("filters", filters);
  // console.log(products);

  const removeProduct = async (id) => {
    const p = window.confirm("Are you sure you want to delete this product?");
    if (!p) return;
    let res = await ProductsRest.removeProduct(id);
    if (res?.status === 400) {
        toast.error(res?.message || 'Cannot be deleted!')
        return;
    } else if (res?.status === 200) {
        toast.success(res?.message || 'Successfully deleted!')
        setProducts(products.filter(p => p.id !== id))
    } else {
        toast.error("Product already removed!")
    }
    // console.log('res', res)
  }

  console.log('filteretProducts', filteredProducts.length);

  const footerActions = [
    { label: 'Cancel', action: onClickModalClose, theme: 'ghost' },
    { label: 'Confirm', action: removeProduct},
  ];

  return (
    <PrivateTemplate>
      <PrivateTemplate.AppSidebar label="Filters" icon={ICON_FUNNEL}>
        <div className="products-filters">
          <Input
            type="text"
            label="Product name"
            height="medium"
            name="name"
            value={filters.name}
            onChange={handleOnChange}
          />
          <MultiSelect
            label="Product Type"
            isMulti={false}
            isClearable={true}
            name="typeOfProduct"
            value={{
              value: filters.typeOfProduct,
              label: filters.typeOfProduct,
            }}
            options={productTypes}
            onChange={handleOnChangeType}
          />
          <div className="financial-filters__switch-group">
            <Switch
              label="List 1"
              onChange={handleOnChangeSwitch}
              name="list_one"
              value={filters.list_one}
            />
            <Switch
              label="List 2"
              onChange={handleOnChangeSwitch}
              name="list_two"
              value={filters.list_two}
            />
            <Switch
              label="List 3"
              onChange={handleOnChangeSwitch}
              name="list_three"
              value={filters.list_three}
            />
          </div>
          {/* <Input type="text" label="Choose Drugstore" height="medium" name="pharmacy" value={filters.pharmacy} onChange={handleOnChange} /> */}
        </div>
      </PrivateTemplate.AppSidebar>
      <div className="products-list">
        <div className="products-list__header">
          <div className="products-list__header-left">
            <img src={ICON_LIST} />
            <span>Products</span>
          </div>
          <Button
            type="warning"
            label="Add Product"
            onClick={onClickAddProduct}
          />
        </div>
        {loading ? (
          <img src={ANIMATION_LOADER} className="loader" />
        ) : (
          <Table columns={productsColumns}>
            {filteredProducts.map((p, i) => (
              <tr className="products-row" key={i}>
                {/* <Table.Cell>{p.id}</Table.Cell> */}
                <Table.Cell>{p.nameOfProduct}</Table.Cell>
                <Table.Cell>{p.typeOfProduct}</Table.Cell>
                <Table.Cell>{p.listOfProduct}</Table.Cell>
                <Table.Cell>{p.priceOfProduct}</Table.Cell>
                <Table.Cell className="left-auto">
                  <img
                    src={ICON_EDIT}
                    className="products-row__edit"
                    onClick={() => handleOnClickEdit(p.id)}
                  />
                  <img
                    src={ICON_DELETE}
                    className="products-row__edit"
                    onClick={() => removeProduct(p.id)}
                  />
                </Table.Cell>
              </tr>
            ))}
            
          </Table>
        )}
        
      </div>
    </PrivateTemplate>
  );
};

export default Products;
