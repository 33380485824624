import React, { useEffect, useState } from "react";
import englishToCyrillic from "util/englishToCyrillic";
import { useNavigate } from "react-router-dom";
// templates
import PrivateTemplate from "comp/templates/PrivateTemplate";
// widgets
import Table from "comp/widgets/Table";
// ui comp
import Button from "comp/ui/Button";
import Input from "comp/ui/Input";
// icons
import ICON_LIST from "assets/icons/ico-list.svg";
import ICON_DELETE from "assets/icons/delete.png";
import salesDeals from "services/rest/sales-deals";
import { toast } from 'react-toastify';

export default function Quarterly() {
  const [deals, setDeals] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({ quarterlyName: "" });

  const dealsColumns = [
    { name: "Name" },
    { name: "Sum from" },
    { name: "Sum to" },
    { name: "Percentage" },
    { name: "Date from" },
    { name: "Date to" },
    { name: "Actions" },
  ];

  useEffect(() => {
    (async () => {
      let d = await salesDeals.getAllQ();
      setDeals(d);
    })();
  }, []);

  const formattedQuarterly = deals.filter(
    (o) =>
      !filters.quarterlyName ||
      o.quarterlyName
        .trim()
        .toLowerCase()
        .includes(filters.quarterlyName.toLowerCase()) ||
      o.quarterlyName
        .trim()
        .toLowerCase()
        .includes(englishToCyrillic(filters.quarterlyName))
  );

  const removeSalesDealQ = async (id) => {
    const p = window.confirm("Are you sure you want to delete this quarterly deal?");
    if (!p) return;
    let res = await salesDeals.removeQ(id);
    console.log('res', res)
    if (res?.status === 400) {
        toast.error(res?.message || 'Cannot be deleted!')
        return;
    } else if (res?.status === 200) {
        toast.success(res?.message || 'Successfully deleted!')
        setDeals(deals.filter(d => d.id !== id))
    } else {
        toast.error("Quarterly deal already removed!")
    }
  };

  return (
    <PrivateTemplate>
      <PrivateTemplate.AppSidebar>
        <div className="owners-filters">
          <Input
            label="Име"
            name="nameOfDeal"
            value={filters.quarterlyName}
            onChange={(e) =>
              setFilters({ ...filters, quarterlyName: e.target.value })
            }
          />
        </div>
      </PrivateTemplate.AppSidebar>
      <div className="products-list">
        <div className="products-list__header">
          <div className="products-list__header-left">
            <img src={ICON_LIST} />
            <span>Quarterly Deals</span>
          </div>
          <Button
            type="warning"
            label="Create new deal"
            onClick={() => navigate("/quarterly-deals/add")}
          />
        </div>
        <Table columns={dealsColumns}>
          {formattedQuarterly?.map((d, i) => {
            return (
              <tr className="products-row" key={i}>
                <Table.Cell>{d.quarterlyName}</Table.Cell>
                <Table.Cell>{d.sumFrom}</Table.Cell>
                <Table.Cell>{d.sumTo}</Table.Cell>
                <Table.Cell>{d.percentage}%</Table.Cell>
                <Table.Cell>{d.dateFrom}</Table.Cell>
                <Table.Cell>{d.dateTo}</Table.Cell>
                <Table.Cell>
                  <span
                    className="deals-row__add"
                    onClick={() => navigate(`view/${d.id}`)}
                  >
                    View Deal
                  </span>
                </Table.Cell>
                <Table.Cell>
                <img
                    src={ICON_DELETE}
                    className="products-row__edit"
                    onClick={() => removeSalesDealQ(d.id)}
                  />
                </Table.Cell>
              </tr>
            );
          })}
        </Table>
      </div>
    </PrivateTemplate>
  );
}
