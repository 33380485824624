const getAll = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });

    const data = await res.json();
    console.log("data", data.data);
    return data.data;
  } catch (error) {
    console.log("error", error);
  }
};

const getProductById = async (id) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data;
  } catch (error) {
    console.log("error", error);
  }
};

const updateProductById = async (id, product) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/updateProduct/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(product),
      }
    );

    return res.json();
    // no need to return anything
    // const data = await res.json();
    // console.log('data', data)
    // return data.data;
  } catch (error) {
    console.log("error", error);
  }
};

const createProduct = async (product) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/api/storeProduct`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
    body: JSON.stringify(product),
  });
  if (res.status >= 400) {
    const json = await res.json();
    throw new Error(json?.message || "Something went wrong");
  }
};

const removeProduct = async (id) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/deleteProduct/${id}`, {method: 'DELETE', headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          }});
        return await res.json();
    } catch (error) {
        console.log('error', error);
    }
}

export default {
  getAll,
  getProductById,
  updateProductById,
  createProduct,
  removeProduct
};
