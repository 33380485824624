import React, { useState, useEffect } from "react";
import PrivateTemplate from "comp/templates/PrivateTemplate";
import ReportsRest from "services/rest/reports";
import PharmaciesRest from "services/rest/pharmacies";
import MultiSelect from "comp/ui/MultiSelect";
import Input from "comp/ui/Input";
import ICON_LIST from "assets/icons/ico-list.svg";
import ICON_EXCEL from "assets/icons/excel.png";
import ICON_DELETE from "assets/icons/delete.png";
import { toast } from "react-toastify";
import "./style.css";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [filters, setFilters] = useState({ pharmacyIds: [], date: "" });

  useEffect(() => {
    (async () => {
      let r = await ReportsRest.getAll();
      setReports(r);
      let ph = await PharmaciesRest.getAll();
      setPharmacies(
        ph?.map((p) => {
          return {
            value: p.id,
            label: p.drugstoreName,
          };
        })
      );
    })();
  }, []);

  const handleOnClickDownload = async (id, rf) => {
    await ReportsRest.download(id, rf);
  };

  const handleOnClickDelete = async (id) => {
    const p = window.confirm("Are you sure you want to delete this file?");
    if (!p) return;
    await ReportsRest.remove(id);
    const newReports = reports.map((report) => ({
      ...report,
      files: report.files.filter((f) => f.id !== id),
    }));
    setReports(newReports);
    toast.success("File is deleted successfully!");
  };
//   console.log('reports', reports)

    const rows = reports?.flatMap((drugstore) => 
        drugstore.files
        .map((file) => ({
            drugstoreId: drugstore.id,
            drugstoreName: drugstore.drugstoreName,
            file,
        }))
        .filter((r) => {
            const hasPharmacyIds = filters.pharmacyIds && filters.pharmacyIds.length > 0;

            return (hasPharmacyIds ? filters.pharmacyIds.includes(r.drugstoreId) : true) &&
                (filters.date ? filters.date === r.file.month : true);
        })
    );
    
  return (
    <PrivateTemplate>
      <PrivateTemplate.AppSidebar>
        <MultiSelect
          options={pharmacies}
          isMulti={true}
          label="Веледрогерија"
          name="pharmacyIds"
          onChange={(e) => {
            setFilters({ ...filters, pharmacyIds: e?.map((v) => v.value) });
          }}
        />
        <Input 
            name="date"
            type="date"
            onChange={(e) => {
                setFilters({...filters, date: e.target.value?.slice(0, 7)})
            }}
        />
      </PrivateTemplate.AppSidebar>
      <div className="reports-list">
        <div className="reports-list__header">
          <img src={ICON_LIST} />
          <span>Reports</span>
        </div>
        <ul className="reports-list__main-item__files-header">
          <li>Веледрогерија</li>
          <li>Документ</li>
          <li>Месец</li>
          <li className="left-auto">Акции</li>
        </ul>
        <div className="reports-list__main">
          {rows?.map((r) => {
            console.log('filters.date:', filters.date);
            console.log('r.file.month:', r.file.month);
            return (
              <div key={r.file.id} className="reports-list__main-item">
                <div className="reports-list__main-item__files">
                  <ul className="reports-list__main-item__files-list">
                    <li>{r.drugstoreName} {r.file.id}</li>
                    <li>{r.file.fileName}</li>
                    <li>{r.file.month}</li>
                    <li className="left-auto reports-actions">
                      <img
                        className="icon-excel"
                        src={ICON_EXCEL}
                        onClick={() => handleOnClickDownload(r.file.id, r.file)}
                      />
                      <img
                        className="icon-excel"
                        src={ICON_DELETE}
                        onClick={() => handleOnClickDelete(r.file.id)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Reports;
