import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import englishToCyrillic from "util/englishToCyrillic";
// templates
import PrivateTemplate from "comp/templates/PrivateTemplate";
// widgets
import Table from "comp/widgets/Table";
// services
import OwnersRest from "services/rest/owners";
// ui comps
import Input from "comp/ui/Input";
import Button from "comp/ui/Button";
import { toast } from 'react-toastify';
// icons
import ICON_EDIT from "assets/icons/ico-edit.svg";
import ICON_LIST from "assets/icons/ico-list.svg";
import ICON_DELETE from "assets/icons/delete.png";
import ICON_FUNNEL from "assets/icons/ico-funnel.svg";
// styles
import "./style.css";

const ownersColumns = [
  { name: "Name" },
  { name: "Email" },
  { name: "Phone" },
  { name: "Actions", className: "left-auto__header" },
];

const Owners = () => {
  const navigate = useNavigate();
  const [owners, setOwners] = useState([]);
  const [filters, setFilters] = useState({ ownerName: "" });

  const fetchOwners = async () => {
    let o = await OwnersRest.getAll();
    setOwners(o);
  };

  useEffect(() => {
    fetchOwners();
  }, []);

  const filteredOwners = owners.filter(
    (o) =>
      !filters.ownerName ||
      o.ownerName
        .trim()
        .toLowerCase()
        .includes(filters.ownerName.toLowerCase()) ||
      o.ownerName
        .trim()
        .toLowerCase()
        .includes(englishToCyrillic(filters.ownerName))
  );

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const onClickAddOwner = () => {
    navigate("/owners/add");
  };

  const removeOwner = async (id) => {
    const p = window.confirm("Are you sure you want to delete this owner?");
    if (!p) return;
    let res = await OwnersRest.removeOwner(id);
    console.log('res', res)
    if (res?.status === 400) {
        toast.error(res?.message || 'Cannot be deleted!')
        return;
    } else if (res?.status === 200) {
        toast.success(res?.message || 'Successfully deleted!')
        setOwners(owners.filter(d => d.id !== id))
    } else {
        toast.error("Owner already removed!")
    }
  };

  console.log("owners", owners);

  return (
    <PrivateTemplate>
      <PrivateTemplate.AppSidebar>
        <div className="owners-filters">
          <Input
            label="Име"
            name="ownerName"
            value={filters.ownerName}
            onChange={handleOnChange}
          />
        </div>
      </PrivateTemplate.AppSidebar>
      <div className="products-list">
        <div className="products-list__header">
          <div className="products-list__header-left">
            <img src={ICON_LIST} />
            <span>Owners</span>
          </div>
          <Button type="warning" label="Add Owner" onClick={onClickAddOwner} />
        </div>
        <Table columns={ownersColumns}>
          {filteredOwners?.map((o, i) => {
            return (
              <tr className="products-row" key={i}>
                <Table.Cell>{o.ownerName}</Table.Cell>
                <Table.Cell>{o.email}</Table.Cell>
                <Table.Cell>{o.ownerPhone}</Table.Cell>
                <Table.Cell className="left-auto">
                  <img
                    src={ICON_EDIT}
                    className="owners-row__edit"
                    onClick={() => navigate(`/owners/${o.id}`)}
                  />
                   <img
                    src={ICON_DELETE}
                    className="products-row__edit"
                    onClick={() => removeOwner(o.id)}
                  />
                </Table.Cell>
              </tr>
            );
          })}
        </Table>
      </div>
    </PrivateTemplate>
  );
};

export default Owners;
